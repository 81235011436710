import React from 'react';
import styled from 'styled-components';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { Spacing } from 'styleguide/spacing';
import { TextMD } from 'styleguide/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.md}px;
  min-height: 200px;
`;

export const EmptyGraph = ({ title }: { title?: string }) => {
  return (
    <Container>
      <DoubleCircledIcon type="bar-chart"></DoubleCircledIcon>
      <TextMD style={{ textAlign: 'center', marginTop: Spacing.md, marginBottom: Spacing.md }}>
        {title || 'No data yet.'}
      </TextMD>
    </Container>
  );
};

import React from 'react';
import { TextLG, TextMDRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import DownloadAppstore from 'assets/download-appstore.svg';
import DownloadGooglePlay from 'assets/download-googleplay.svg';
import { Card } from 'styleguide/Card';
import styled from 'styled-components';
import { WorkoutCell } from 'components/WorkoutCell/WorkoutCell';
import { WorkoutCellViewModel } from 'components/WorkoutCell/WorkoutCellViewModel';
import { observer } from 'mobx-react-lite';
import { Workout } from 'hevy-shared';
import { FlexRow } from 'styleguide/Row';

const CTAContainer = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.lg}px;
  height: 240px;
`;

const Content = styled.div`
  display: grid;
  max-width: 1024px;
  justify-content: center;
  margin-top: ${Spacing.lg}px;
  grid-template-columns: 5fr 3fr;
  gap: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const LeftContainer = styled(View)`
  gap: ${Spacing.sm}px;
`;

const RightContainer = styled(View)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const CTACard = ({ username, layout }: { username: string; layout?: 'mobile' | 'desktop' }) => {
  return (
    <CTAContainer>
      <TextLG
        style={{ paddingBottom: Spacing.sm }}
      >{`Explore more of ${username}'s workouts!`}</TextLG>
      <TextMDRegular
        style={{
          paddingBottom: Spacing.md,
          textAlign: layout === 'desktop' ? 'center' : 'initial',
        }}
        type="secondary"
      >
        {`To view ${username}'s  user profile and track your workouts, download Hevy for free.`}
      </TextMDRegular>
      <FlexRow style={{ marginBottom: Spacing.md, gap: Spacing.sm }}>
        <a href="https://hevyapp.app.link/1TLxztjk2db">
          <DownloadAppstore />
        </a>
        <a href="https://hevyapp.app.link/d1yXlPlk2db">
          <DownloadGooglePlay />
        </a>
      </FlexRow>
    </CTAContainer>
  );
};

export const Unauthenticated = observer(
  ({ username, lastWorkout }: { username: string; lastWorkout?: Workout }) => {
    return !!lastWorkout ? (
      <Content>
        <LeftContainer>
          <WorkoutCell vm={new WorkoutCellViewModel(lastWorkout)} publicWorkout={true} />
          <CTACard username={username} layout="desktop" />
        </LeftContainer>
        <RightContainer>
          <CTACard username={username} />
        </RightContainer>
      </Content>
    ) : (
      <View style={{ marginTop: Spacing.lg }}>
        <CTACard username={username} layout="desktop" />
      </View>
    );
  },
);

import React from 'react';
import { ProfileHeader } from './ProfileHeader';
import { Screen } from 'styleguide/ScreenContainer';

export const UserNotFoundScreen = () => {
  return (
    <Screen>
      <ProfileHeader
        username="404"
        workoutCount="-"
        followerCount="-"
        followingCount="-"
        showFollowButton={false}
      />
    </Screen>
  );
};

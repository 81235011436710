import { RoutineMetadata } from 'hevy-shared';
import { Link } from 'components/Link';
import React from 'react';
import { SecondaryButton } from 'styleguide/Buttons';
import { Card } from 'styleguide/Card';
import { TextLG, TextLGRegular, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { modal } from 'components/Modals/ModalManager';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';

export const RoutinesCard = ({ routines }: { routines?: RoutineMetadata[] }) => {
  return !routines || routines?.length === 0 ? (
    <>
      <Card style={{ padding: Spacing.lg, gap: Spacing.md }}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <FlexRow style={{ gap: Spacing.sm }}>
            <TextLG>Routines</TextLG>
            <TextLGRegular>(0)</TextLGRegular>
          </FlexRow>
        </FlexRow>
      </Card>
    </>
  ) : (
    <Card style={{ padding: Spacing.lg, gap: Spacing.md }}>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <FlexRow style={{ gap: Spacing.sm }}>
          <TextLG>Routines</TextLG>
          <TextLGRegular>{`(${routines.length})`}</TextLGRegular>
        </FlexRow>
        {routines.length > 7 && (
          <SecondaryButton
            title="View All"
            onClick={() => {
              modal.openRoutineListModal({ routines: routines });
            }}
          />
        )}
      </FlexRow>
      <View style={{ gap: Spacing.sm }}>
        {routines.slice(0, 7).map(r => (
          <Link key={r.short_id} href={`/routine/${r.short_id}`}>
            <Card style={{ padding: Spacing.md }}>
              <TextSMMedium>{r.title}</TextSMMedium>
            </Card>
          </Link>
        ))}
      </View>
    </Card>
  );
};

const RoutinesRow = styled(FlexRow)`
  overflow-x: scroll;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.md}px;

  /* Hide the scroll indicator */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome */
  }
`;

export const MobileRoutineCard = ({ routines }: { routines: RoutineMetadata[] }) => {
  return (
    <Card>
      <FlexRow style={{ padding: Spacing.md, paddingBottom: 0, justifyContent: 'space-between' }}>
        <FlexRow style={{ gap: Spacing.sm }}>
          <TextLG>Routines</TextLG>
          <TextLGRegular>{`(${routines.length})`}</TextLGRegular>
        </FlexRow>
        {routines.length > 7 && (
          <SecondaryButton
            title="View All"
            onClick={() => {
              modal.openRoutineListModal({ routines: routines });
            }}
          />
        )}
      </FlexRow>
      <RoutinesRow>
        {routines.slice(0, 7).map(r => (
          <Link key={r.short_id} href={`/routine/${r.short_id}`}>
            <Card
              style={{
                padding: Spacing.md,
                minHeight: 40,
                minWidth: 100,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextSMMedium>{r.title}</TextSMMedium>
            </Card>
          </Link>
        ))}
      </RoutinesRow>
    </Card>
  );
};

import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { WorkoutCellViewModel } from 'components/WorkoutCell/WorkoutCellViewModel';
import { stores } from 'state/stores';
import { fireAndForget } from 'utils/async';
import { log } from 'utils/log';
import { modal } from 'components/Modals/ModalManager';
import API from 'utils/API';
import { UserProfileResult, UserProfileViewState } from './types';

interface WorkoutCell {
  type: 'workout-cell';
  vm: WorkoutCellViewModel;
}

interface ZeroWorkoutsCell {
  type: 'zero-workouts-cell';
}

interface LoadingCell {
  type: 'loading-cell';
  key: string;
}

interface LoginToSeeWorkoutsCell {
  type: 'login-to-see-workouts';
}

type UserProfileCell = WorkoutCell | ZeroWorkoutsCell | LoadingCell | LoginToSeeWorkoutsCell;

interface Props {
  username: string;
  result: UserProfileResult;
}

export class UserProfileViewModel {
  isInitialFetch: boolean = true;
  isFetchingMore: boolean = false;
  profileResult: UserProfileResult;
  username: string;
  workoutImages: string[] = [];

  private hasFetchedAllUserWorkouts: boolean = false;

  constructor({ username, result }: Props) {
    log('👷‍♂️ init UserProfileViewModel');

    makeAutoObservable(this);

    this.profileResult = result;
    this.username = username;
  }

  handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (stores.workouts.getUserWorkouts(this.username).length === 0) return;

    const scrollBottom = e.currentTarget.scrollTop + e.currentTarget.offsetHeight;

    const scrollHeight = e.currentTarget.scrollHeight;
    const fetchMoreThreshold = scrollHeight - 1000;

    if (scrollBottom > fetchMoreThreshold) {
      this.fetchMore();
    }
  };

  fetchUserWorkoutImages = async () => {
    log('🔔 Fetching user workout images');

    const limit = 5;
    const response = await API.getUserWorkoutImages(this.username, limit);
    this.workoutImages = response.data;
  };

  refreshContent = async () => {
    if (this.profileResult.type === 'unauthenticated') {
      return;
    }

    log('🔔 Fetching user profile content');

    try {
      await fireAndForget([
        stores.workouts.refreshUserWorkouts(this.username),
        this.fetchUserWorkoutImages(),
      ]);
    } catch {
    } finally {
      this.isInitialFetch = false;
    }
  };

  fetchMore = async () => {
    if (this.isFetchingMore || this.hasFetchedAllUserWorkouts) return;

    log('🛻 Fetching more workouts');

    const prefetchWorkoutCount = stores.workouts.getUserWorkouts(this.username).length;

    this.isFetchingMore = true;
    try {
      await stores.workouts.fetchMoreUserWorkouts(this.username);

      if (prefetchWorkoutCount === stores.workouts.getUserWorkouts(this.username).length) {
        this.hasFetchedAllUserWorkouts = true;
      }
    } catch {
    } finally {
      this.isFetchingMore = false;
    }
  };

  handleFollowingsClick = async () => {
    modal.showUserListModal({ mode: 'following', username: this.username });
  };

  handleFollowerClick = async () => {
    modal.showUserListModal({ mode: 'followers', username: this.username });
  };

  get showFollowButton() {
    if (!stores.auth.isAuthenticated) {
      return false;
    }

    return this.username !== stores.account.username;
  }

  get numberOfWorkouts() {
    return this.profileResult.profile.workout_count;
  }

  get viewState(): UserProfileViewState {
    const { profileResult } = this;

    if (profileResult.type === 'unauthenticated') {
      if (!profileResult.profile.private_profile) {
        /**
         * User is unauthenticated and this is a public profile
         */

        return {
          username: profileResult.profile.username,
          fullName: profileResult.profile.full_name,
          description: profileResult.profile.description,
          profilePic: profileResult.profile.profile_pic,
          workoutCount: `${profileResult.profile.workout_count}`,
          followerCount: `${profileResult.profile.follower_count}`,
          followingCount: `${profileResult.profile.following_count}`,
          lastWorkout: profileResult.profile.last_workout,
        };
      } else {
        /**
         * User is unauthenticated and this is a private profile
         */
        return {
          username: profileResult.profile.username,
          fullName: profileResult.profile.full_name,
          profilePic: profileResult.profile.profile_pic,
          workoutCount: `${profileResult.profile.workout_count}`,
          followerCount: `${profileResult.profile.follower_count}`,
          followingCount: `${profileResult.profile.following_count}`,
        };
      }
    }

    if (
      profileResult.type === 'authenticated' &&
      profileResult.profile.private_profile &&
      profileResult.profile.following_status !== 'following'
    ) {
      /**
       * User is authenticated but isn't following this private user
       */
      return {
        username: profileResult.profile.username,
        fullName: profileResult.profile.full_name,
        profilePic: profileResult.profile.profile_pic,
        workoutCount: `${profileResult.profile.workout_count}`,
        followerCount: `${profileResult.profile.follower_count}`,
        followingCount: `${profileResult.profile.following_count}`,
      };
    }

    /**
     * Otherwise, return user profile
     * 1- Authenticated and following this private user
     * 2- Authenticated and the user's profile is public (doesn't matter if following or not)
     */
    return {
      username: profileResult.profile.username,
      fullName: profileResult.profile.full_name,
      description: profileResult.profile.description,
      profilePic: profileResult.profile.profile_pic,
      link: profileResult.profile.website_link,
      workoutCount: `${profileResult.profile.workout_count}`,
      followerCount: `${profileResult.profile.follower_count}`,
      followingCount: `${profileResult.profile.following_count}`,
      routines: profileResult.profile.routines,
      graphData: profileResult.profile.weekly_workout_durations.map(d => {
        return {
          date: dayjs(d.week_start_date).format('MMM D'),
          value: d.total_seconds,
        };
      }),
    };
  }

  get isAuthorizedToViewProfile(): boolean {
    const isPrivateProfile = this.profileResult.profile.private_profile;
    const isOwnProfile = this.profileResult.profile.username === stores.account.username;

    if (isOwnProfile) {
      // we can always view our own profile
      return true;
    }

    if (
      this.profileResult.type === 'authenticated' &&
      this.profileResult.profile.following_status === 'following'
    ) {
      // we are following this user, so we can view their profile too
      return true;
    }

    // otherwise, we can only view the profile if it's not private
    return !isPrivateProfile;
  }

  get cells(): UserProfileCell[] {
    if (!stores.auth.isAuthenticated) {
      return [{ type: 'login-to-see-workouts' }];
    }

    if (!this.isAuthorizedToViewProfile) {
      return [];
    }

    if (this.isInitialFetch) {
      return [
        { type: 'loading-cell', key: '1' },
        { type: 'loading-cell', key: '2' },
      ];
    }

    const workouts = stores.workouts.getUserWorkouts(this.username);

    if (workouts.length === 0) {
      return [
        {
          type: 'zero-workouts-cell',
        },
      ];
    }

    return workouts.map(w => {
      return {
        type: 'workout-cell',
        vm: new WorkoutCellViewModel(w),
      };
    });
  }
}

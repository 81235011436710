import React from 'react';
import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import cookie from 'cookie';
import Head from 'next/head';
import API from 'utils/API';
import { UserProfileScreen } from 'screens/Profile/UserProfileScreen';
import { BootstrapLoadingWrapper } from 'components/BootstrapLoadingWrapper';
import { formatProfilePicForSocial } from 'utils/imageUtils';
import { UserNotFoundScreen } from 'screens/Profile/components/UserNotFoundScreen';
import { UserProfileResult } from 'screens/Profile/types';

interface UserProfileProps {
  result: UserProfileResult | { type: 'error' };
  isAuthenticated: boolean;
}

export const getServerSideProps: GetServerSideProps = async (
  context,
): Promise<GetServerSidePropsResult<UserProfileProps>> => {
  const { username } = context.query;
  const rawCookies = context.req?.headers.cookie;
  const cookies = rawCookies ? cookie.parse(rawCookies) : {};
  const authToken = cookies['auth-token'];

  try {
    if (typeof username !== 'string') throw new Error();

    if (authToken) {
      API.setAuthToken(cookies['auth-token']);
      const result = await API.getUserProfile(username);

      return {
        props: {
          result: { type: 'authenticated', profile: result.data },
          isAuthenticated: !!authToken,
        },
      };
    } else {
      const result = await API.getPublicUserProfile(username);
      return {
        props: {
          result: { type: 'unauthenticated', profile: result.data },
          isAuthenticated: !!authToken,
        },
      };
    }
  } catch {
    return {
      props: {
        result: { type: 'error' },
        isAuthenticated: !!authToken,
      },
    };
  }
};

export default function UserProfile({ result }: UserProfileProps) {
  const defaultMetaImageUrl = 'https://www.hevy.com/images/default-profile-preview.png';
  const defaultTwitterMetaImageUrl =
    'https://www.hevy.com/images/default-profile-preview-twitter.png';

  const metadata =
    result.type !== 'error'
      ? {
          title: `Hevy | ${result.profile.username}`,
          description: `${result.profile.username}'s Hevy Profile`,
          url: `https://hevy.com/user/${result.profile.username}`,
          username: result.profile.username,
          imageUrl: formatProfilePicForSocial(result.profile.profile_pic) || defaultMetaImageUrl,
          twitterImageUrl:
            formatProfilePicForSocial(result.profile.profile_pic) || defaultTwitterMetaImageUrl,
        }
      : undefined;

  return (
    <>
      {metadata && (
        <Head>
          <title>{metadata.title}</title>
          <meta name="description" content={metadata.description} />
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href={metadata.url} />
          <meta property="og:locale" content="en_US" />
          <meta name="og:type" content="website" />
          <meta property="og:title" content={metadata.title} />
          <meta property="og:description" content={metadata.description} />
          <meta property="og:url" content={metadata.url} />
          <meta property="og:site_name" content="Hevy - Social Workout Tracker" />
          <meta property="og:image" content={metadata.imageUrl} />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="400" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@hevyapp" />
          <meta name="twitter:site" content="@hevyapp" />
          <meta name="twitter:title" content={metadata.title} />
          <meta name="twitter:description" content={metadata.description} />
          <meta name="twitter:image" content={metadata.twitterImageUrl} />
          <meta name="twitter:label1" content="User" />
          <meta name="twitter:data1" content={metadata.username} />
        </Head>
      )}
      <BootstrapLoadingWrapper>
        {metadata && result.type !== 'error' ? (
          <UserProfileScreen result={result} username={metadata.username} />
        ) : (
          <UserNotFoundScreen />
        )}
      </BootstrapLoadingWrapper>
    </>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Card } from 'styleguide/Card';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { TextLG, TextMD } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';

const Container = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  padding: ${Spacing.lg}px;
  margin-top: ${Spacing.lg}px;
  gap: ${Spacing.lg}px;
`;

interface Props {
  username: string;
}

export const ZeroWorkouts = ({ username }: Props) => {
  return (
    <>
      <TextLG>Workouts</TextLG>
      <Container>
        <DoubleCircledIcon type="exercise"></DoubleCircledIcon>
        <TextMD>{`${username} hasn't worked out yet`}</TextMD>
      </Container>
    </>
  );
};

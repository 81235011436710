import React from 'react';
import styled from 'styled-components';
import { Card } from 'styleguide/Card';
import { DoubleCircledIcon } from 'styleguide/CircledIcons';
import { TextLG, TextMDRegular } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';

const Container = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${Spacing.md}px 0px ${Spacing.lg}px 0px;
  height: 240px;
`;

interface Props {
  username: string;
}

export const PrivateProfile = ({ username }: Props) => {
  return (
    <>
      <TextLG style={{ marginTop: Spacing.lg }}>Workouts</TextLG>
      <Container>
        <DoubleCircledIcon
          containerStyle={{ marginBottom: Spacing.md }}
          type="lock"
        ></DoubleCircledIcon>
        <TextLG
          style={{ textAlign: 'center', paddingBottom: Spacing.sm }}
        >{`${username} has a private profile.`}</TextLG>
        <TextMDRegular style={{ textAlign: 'center' }} type="secondary">
          {`They must approve your follow request before you can see their workouts.`}
        </TextMDRegular>
      </Container>
    </>
  );
};
